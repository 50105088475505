
























import { defineComponent } from '@vue/composition-api'
import VueTelInputVuetify from 'vue-tel-input-vuetify/lib/vue-tel-input-vuetify.vue'

export default defineComponent({
  name: 'PhoneNumber',
  components: {
    VueTelInputVuetify,
  },
  props: {
    value: {
      type: String,
      required: false,
      default: null,
    },
    label: {
      type: String,
      required: false,
    },
    required: {
      type: Boolean,
      default: false,
      required: false,
    },
    classes: {
      type: String,
      required: false,
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data () {
    return {
      phoneNumber: '',
      onlyCountries: ['FR', 'BE', 'DE', 'ES', 'GP', 'IT', 'IE', 'LU', 'MQ', 'YT', 'GB', 'GF', 'PF', 'NC', 'BL', 'CH', 'MF', 'PM', 'RE', 'WF'],
    }
  },
  created () {
    if (this.value) {
      this.phoneNumber = this.value
    }
  },
  watch: {
    value: {
      handler () {
        this.phoneNumber = this.value
      },
    },
  },
  methods: {
    onUpdate (value: string) {
      this.$emit('input', value)
    },
  },
})
